import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { API, Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Badge, User } from 'src/modules/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private loadingService: LoadingService) { }

  async getUsers() {
    const uri = `/v1/users`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })).users as User[];

    return res;
  }

  async getUser(userName: string) {
    const uri = `/v1/users/${userName}`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })).user as User;

    return res;
  }

  async getCheckedInUsers(location: string, policy: string, today: string) {
    const uri = `/v1/users?checkedInTo=${location}&locations=${policy}&lastActiveAfter=${today}`
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })).users as User[];

    return res;
  }

  async getBadges(location?: string) {
    try {
      const response = await fetch(`${environment.endpoint}/v1/badges/${location}`, {
        mode: "cors",
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }),
      });

      const data = await response.json();
      return data.badges as Badge[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async watch(site: string) {
    await API.put('backend', `/v1/watch/checkin/${encodeURIComponent(site)}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    });
  }

  async unWatch(site: string) {
    await API.del('backend', `/v1/watch/checkin/${encodeURIComponent(site)}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    });
  }

  async removeSubscription(id: string) {
    try {
      this.loadingService.add()
      const uri = `/v1/subscriptions/${id}`;
      await API.del('backend', uri, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });

      this.setNotifications(null);
    } catch (error) {
      throw new Error('Error removing subscription.');
    } finally {
      this.loadingService.done()
    }
  }

  async addSubscription(sub: PushSubscription) {
    this.loadingService.add()
    const uri = `/v1/subscriptions`;
    const res = (await API.post('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: sub
    })).id;
    this.setNotifications(res)
  }

  private $notificationId = new ReplaySubject<string | null>();
  public notificationId$ = this.$notificationId.asObservable();
  private notificationKey = 'notifications';

  public checkNotifications() {
    const item = localStorage.getItem(this.notificationKey);
    this.$notificationId.next(item);
    return item;
  }

  public setNotifications(setVal: string | null) {
    if (!setVal)
      localStorage.removeItem(this.notificationKey);
    else
      localStorage.setItem(this.notificationKey, setVal);
    return this.checkNotifications();
  }

  async patchUser(userName: string, userData: User) {
    const uri = `/v1/users/${userName}`;
    const res = (await API.patch('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: userData
    }))

    return res;
  }

  async getUsersVideosWatchCount(userName: string): Promise<number> {
    const baseUrl = environment.endpoint
    const url = `${baseUrl}/v1/openevents/${userName}?event=watchedVideo`

    await fetch(url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        return response.total
      })
      .catch((err) => {
        console.error("Error:", err)
      });
    return 0
  }

  async getUsersUploadCount(userName: string): Promise<number> {
    const baseUrl = environment.endpoint;
    const url = `/v1/files/${encodeURIComponent(userName)}`;
    await fetch(baseUrl + url, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }),
    })
      .then((response: Response) => response.json())
      .then((response) => {
        const usersCount = response.files
        return usersCount.length
      })
      .catch((err) => {
        console.log("Error:", err);
      });
    return 0
  }

  async getUserCheckInCount(userName: string) {
    const uri = `/v1/openevents/${userName}?event=checkin`;
    const res = (await API.get('backend', uri, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    }));
    return res
  }
  
  async getFreeItems() {
    try {
      const baseUrl = environment.endpoint;
      const location = JSON.parse(localStorage.getItem("/our-visitors")!);
      const response = await fetch(baseUrl + `/v1/materials/${location}`, {
        mode: "cors",
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }),
      })
        .then((response) => response.json())
        .then((response) => response.materials);
      return response

    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async changePassword(username: string, password: string) {
    try {
      const response = await fetch(`${environment.endpoint}/v1/users/${username}/password`, {
        mode: "cors",
        method: "POST",
        headers: new Headers({
          Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }),
        body: JSON.stringify({ password: password })
      });

      if (response.ok) {
        return response;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  }
}
