import { DialogQrComponent } from './dialog-qr/dialog-qr.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { UserService } from 'src/assets/services/user.service';
import { User } from 'src/modules/interfaces';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})

export class InfoCardComponent {
  @Input() profileData: User = {
    username: "",
    email: "",
    avatar: "",
    attributes: {
      nickname: "",
      gender: "",
      "custom:birthdate": new Date(2000 - 10 - 10),
      "custom:zipcode": 0,
      homelocation: ""
    },
    groups: [],
    badges: [],
    materials: []
  };

  badgeNames: string[] = [];
  countWatchedVideos = -1;
  countUploadedFiles = -1;
  countWeek = -1;
  countTotal = -1;
  freeItems = 'X';

  constructor(public dialog: MatDialog, public userService: UserService) { }

  ngOnInit(): void {
    this.fetchUserProgress();
  }

  updateBadge(name: string) {
    if (this.badgeNames.includes(name)) {
      this.badgeNames.splice(this.badgeNames.indexOf(name), 1);
    } else {
      this.badgeNames.push(name);
    }
  }

  getImageForBadge(name: string) {
    const url = "../../../../assets/svg/svg/Badges/";

    return url + name + '.svg';
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(DialogQrComponent, {
      maxWidth: '500px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        username: this.profileData.username
      }
    });
  }

  async fetchUserProgress() {
    try {
      const responseVideos = await this.userService.getUsersVideosWatchCount(this.profileData.username);
      this.countWatchedVideos = responseVideos;
      const responseFiles = await this.userService.getUsersUploadCount(this.profileData.username);
      this.countUploadedFiles = responseFiles;
      const responseWeeklyLogin = await this.userService.getUserCheckInCount(this.profileData.username)
      this.countWeek = responseWeeklyLogin.thisWeek;
      const responseTotalLogin = await this.userService.getUserCheckInCount(this.profileData.username)
      this.countTotal = responseTotalLogin.total;
    } catch (error) {
      this.countWatchedVideos = 0;
    }
  }

}



