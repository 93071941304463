import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/assets/services/user.service';
import { User } from 'src/modules/interfaces';

@Component({
  selector: 'app-dialog-delete-avatar',
  templateUrl: './dialog-delete-avatar.component.html',
  styleUrls: ['./dialog-delete-avatar.component.scss']
})
export class DialogDeleteAvatarComponent {
  user: User

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DialogDeleteAvatarComponent>, public snackBar: MatSnackBar, public userService: UserService) {
    this.user = data.user
  }

  async removeAvatar() {
    try {
      const user = { ...this.user, avatar: '' }
      const res = await this.userService.patchUser(this.user.username, user)
      if (res) {
        this.snackBar.open('Avatar raderad', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        this.dialog.close();
      }
    } catch (error) {
      this.snackBar.open('Misslyckades att radera avatar', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
      console.error('Avatar deletion error:', error);
      this.dialog.close();
    }
  }

  closeDialog() {
    this.dialog.close();
  }
}
